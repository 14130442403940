export default [
  {
    name: "BNP",
    value: "BNP",
    logoPath: "/images/leasers/BNP_logo.png",
    displayedLinks: { connexion: true, invoices: true, changeRib: true },
    platformLink: "https://leasingsolutions.espaceclient.fineasy.com/",
    sepaLink: "/images/leasers/mandat_sepa_bnp.pdf",
  },
  {
    name: "FranFinance",
    value: "Franfinance",
    logoPath: "/images/leasers/Franfinance_logo.png",
    displayedLinks: { connexion: false, invoices: true, changeRib: true },
    sepaLink: "/images/leasers/mandat_sepa_franfinance.pdf",
  },
  {
    name: "Grenke",
    value: "Grenke",
    logoPath: "/images/leasers/Grenke_logo.png",
    displayedLinks: { connexion: true, invoices: true, changeRib: true },
    platformLink: "http://login.grenke.net/",
    sepaLink: "/images/leasers/mandat_sepa_grenke.pdf",
  },
  {
    name: "Leasecom",
    value: "Leasecom",
    logoPath: "/images/leasers/Leasecom_logo.png",
    displayedLinks: { connexion: false, invoices: true, changeRib: true },
    sepaLink: "/images/leasers/mandat_sepa_leasecom.pdf",
  },
  {
    name: "Locam",
    value: "Locam",
    logoPath: "/images/leasers/Locam_Logo.png",
    displayedLinks: { connexion: false, invoices: true, changeRib: true },
  },
  {
    name: "NBB Lease",
    value: "NBB Lease",
    logoPath: "/images/leasers/NBB_Lease_Logo.jpg",
    displayedLinks: { connexion: false, invoices: true, changeRib: true },
    sepaLink: "/images/leasers/mandat_sepa_nbb_lease.pdf",
  },
  {
    name: "Grenke España",
    value: "Grenke España",
    logoPath: "/images/leasers/Grenke_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
    automaticMailLeaser: true,
  },
  {
    name: "SG España",
    value: "SG España",
    logoPath: "/images/leasers/Societe_Generale_Logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "Grenke Deutschland",
    value: "Grenke Deutschland",
    logoPath: "/images/leasers/Grenke_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
    automaticMailLeaser: true,
  },
  {
    name: "BNP Deutschland",
    value: "BNP Deutschland",
    logoPath: "/images/leasers/BNP_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "Peac France",
    value: "Peac France",
    logoPath: "/images/leasers/PEAC_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "Peac Germany",
    value: "Peac Germany",
    logoPath: "/images/leasers/PEAC_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "Siemens Spain",
    value: "Siemens Spain",
    logoPath: "/images/leasers/Logo_Siemens.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "HPFS Spain",
    value: "HPFS Spain",
    logoPath: "/images/leasers/HP_Logo.webp",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "Peac UK",
    value: "Peac UK",
    logoPath: "/images/leasers/PEAC_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "Grenke UK",
    value: "Grenke UK",
    logoPath: "/images/leasers/Grenke_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "CF Corporate Finance LTD",
    value: "CF Corporate Finance LTD",
    logoPath: "/images/leasers/CF_Corporate_Finance_LTD_Logo.jpg",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "DLL España",
    value: "DLL España",
    logoPath: "/images/leasers/dll_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "Grenke Portugal",
    value: "Grenke Portugal",
    logoPath: "/images/leasers/Grenke_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "Grenke International",
    value: "Grenke International",
    logoPath: "/images/leasers/Grenke_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "Tecfyspay Sl.",
    value: "Tecfyspay Sl.",
    logoPath: "/images/leasers/tecfys_logo.png",
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
  {
    name: "Upfront",
    value: "Upfront",
    logoPath: null,
    displayedLinks: { connexion: false, invoices: false, changeRib: false },
  },
];
