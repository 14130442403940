import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useDataLayer from "src/hooks/useDataLayer";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import { useHistory } from "react-router-dom";

const Context = createContext({});

export const useCurrentOrderContext = () => useContext(Context);

export const CurrentOrderProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user, reloadUser } = useUserContext();
  const { addToast } = useToastContext();
  const [order, setOrder] = useState({ devices: [] });
  const [comment, setComment] = useState("");
  const [orderSignatory, setOrderSignatory] = useState();
  const isAdmin = Boolean(user?.roles?.find((r) => r.role === "ADMIN"));
  const { trackPurchase } = useDataLayer();
  const history = useHistory();

  const reloadOrder = useCallback(async () => {
    try {
      const fetchedOrder = await user?.api.getOngoingOrder();

      setOrder(fetchedOrder.data);
    } catch (err) {
      console.error(err);
      if ([401, 403].includes(err.response?.status)) {
        console.log("UNAUTHORIZED");
      } else {
        addToast(t("Une erreur est survenue lors de la récupération de la commande"));
      }
    }
  }, [user?.api, t, addToast]);

  const updateOrder = useCallback(async (orderData) => {
    try {
      const updatedOrder = await user?.api.modifyOrder(order.id, orderData);

      setOrder(updatedOrder.data);
    } catch (err) {
      console.error(err);
      addToast(t("Une erreur est survenue lors de la mise à jour de la commande"));
    }
  }, [user?.api, t, addToast, order?.id]);

  const checkCanConfirmOrder = useCallback((errorHandler) => {
    if (!order.devices.length) {
      addToast(t("Votre panier est vide. Impossible de passer commande."));
      history.push("/");

      return false;
    }

    const validations = [
      {
        name: "signatory_id",
        message: t("Vous devez ajouter un signataire"),
        validator: () => Boolean(order.signatory_id),
      },
    ];

    const validationErrors = {};

    for (const validation of validations) {
      if (!validation.validator()) {
        addToast(validation.message);
        validationErrors[validation.name] = validation.message;
      }
    }

    errorHandler(validationErrors);

    return Object.keys(validationErrors).length === 0;
  }, [addToast, history, order, t]);

  const validateOrder = useCallback(async () => {
    await user.api.validateOrder(order?.id, { comment, insurance_consent: true, signatory_id: orderSignatory.id });
    trackPurchase(order);

    const userDataUpdate = {
      city_of_birth: orderSignatory.city_of_birth,
      date_of_birth: orderSignatory.date_of_birth,
      country_of_birth: orderSignatory.country_of_birth,
    };

    await user.api.modifyUser(orderSignatory.id, userDataUpdate);

    await reloadUser();
  }, [user, trackPurchase, order, comment, orderSignatory, reloadUser]);

  useEffect(() => {
    if (user) {
      if (!isAdmin) return;
      reloadOrder();
    }
  }, [user, isAdmin]);

  const values = useMemo(
    () => ({
      order,
      reloadOrder,
      updateOrder,
      comment,
      setComment,
      orderSignatory,
      setOrderSignatory,
      checkCanConfirmOrder,
      validateOrder,
    }),
    [comment, order, orderSignatory, reloadOrder, updateOrder, checkCanConfirmOrder, validateOrder],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
