/* eslint-disable camelcase */
import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";

import MoneyFormat from "src/tools/MoneyFormat";
import offersList from "src/data/offersList";
import {
  Button,
  Drawer,
  Layout, Separator, Text,
} from "tarmac-v3";
import { groupDevicesByProductAndKeyboard } from "src/tools/OrderHelpers";
import { COUNTRIES, CURRENCIES_LANGUAGE_VARIATIONS_MAPPING } from "src/common/i18n-consts";
import ShoppingCartModalItem from "./ShoppingCartModalItem";
import ShoppingCartModalEmpty from "./ShoppingCartModalEmpty";
import SecureMdmCartDialog from "./SecureMdmCartDialog";

const ShoppingCartModal = (props) => {
  const {
    openModal,
    onClose,
    nextStep,
    order,
    reloadOrder,
  } = props;
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addToast } = useToastContext();
  const [orderInfo, setOrderInfo] = useState({});
  const [secureMdmCartDialog, setSecureMdmCartDialog] = useState(false);

  const reloadOrderInfo = useCallback(async () => {
    const fetchedOrder = await user?.api.getOrder(order?.id);

    setOrderInfo(fetchedOrder.data);
  }, [user?.api, setOrderInfo, order]);

  const cartItems = groupDevicesByProductAndKeyboard(orderInfo?.devices);

  useEffect(() => {
    if (order?.id) {
      reloadOrderInfo();
    }
  }, [order]);

  const totalMonthlyFinalRent = orderInfo.rent || 0;

  const totalMonthlyBaseRent = orderInfo?.devices?.reduce(
    (sum, device) => sum + Number(device.base_price),
    0,
  ).toFixed(2);

  const companyActiveOffer = user.getCompanyActiveOffer();

  const companyUsableReferralCredit = user.getCompanyUsableReferralCredit();

  const validateBasket = useCallback(async () => {
    try {
      if (user.companyInfoCompleted()) {
        await user.api.checkCompanySelfEmployeed();
      }

      if (totalMonthlyBaseRent >= 19) {
        nextStep();
      } else {
        const minAmount = 19;
        const languageVariation = CURRENCIES_LANGUAGE_VARIATIONS_MAPPING[order.rent_currency];
        const formattedMinAmount = minAmount.toLocaleString(languageVariation, { style: "currency", currency: order.rent_currency });

        addToast(t("Minimum de commande : {{ minAmount }}", { minAmount: formattedMinAmount }));
      }
    } catch (e) {
      addToast(t("Notre service n'est malheureusement pas disponible pour les particuliers ou entreprises individuelles"));
      console.error(e);
    }
  }, [addToast, nextStep, order?.rent_currency, t, totalMonthlyBaseRent, user]);

  const offerInfo = offersList.find((o) => o.name === companyActiveOffer?.type);

  const companyDiscount = user.getCompanyDiscount(totalMonthlyBaseRent);

  const DrawerActions = useMemo(() => (
    <Layout direction="column" spacing={3} fullWidth>
      <Layout direction="column" spacing={4}>
        {companyActiveOffer || (order && order.referrer_id) || companyUsableReferralCredit ? (
          <Text variant="body2" color="success">
            {t(offerInfo.text)}
          </Text>
        ) : null}
        <Layout direction="column" spacing={1}>
          {orderInfo.devices?.length ? (
            <Layout direction="row" spacing={1}>
              <Text color="secondary">
                {t(`{{cartItemsNb}} article${orderInfo.devices.length > 1 ? "s" : ""}`, {
                  cartItemsNb: orderInfo.devices.length,
                })}
              </Text>
            </Layout>
          ) : null}
          {orderInfo.devices?.length > 0 && (
            <>
              <Layout direction="row" justifyContent="space-between">
                <Text variant="body1" bold>{t("Sous-total :")}</Text>
                <Layout direction="row" spacing={1} alignItems="baseline">
                  <Text variant="body1" bold>
                    <MoneyFormat value={totalMonthlyFinalRent} />
                  </Text>
                  {companyDiscount?.offer_type === "CURRENCY_DISCOUNT" && (
                    <Text
                      variant="body2"
                      color="secondary"
                      sx={{ textDecorationLine: "line-through" }}
                    >
                      <MoneyFormat value={totalMonthlyBaseRent} />
                    </Text>
                  )}
                </Layout>
              </Layout>
              <Layout direction="row" justifyContent="space-between">
                <Text variant="body1" bold>{t("Livraison :")}</Text>
                <Text variant="body1" bold>{t("Offerte")}</Text>
              </Layout>
              <Layout direction="row" justifyContent="space-between">
                <Text variant="body1" bold>{t("Loyer :")}</Text>
                <Text variant="body1" bold>
                  <MoneyFormat value={totalMonthlyFinalRent} />
                  {orderInfo.company.country === COUNTRIES.UNITED_STATES ? t("/mois HT") : t("/mois")}
                </Text>
              </Layout>
            </>
          )}
        </Layout>
      </Layout>
      <Layout direction="row" fullWidth mobileDirection="column" spacing={1}>

        <Button
          variant="outlined"
          color="secondary"
          label={orderInfo.devices?.length > 0 ? t("Ajouter des équipements") : t("Annuler")}
          fullWidth
          onClick={onClose}
        />
        <Button
          variant="contained"
          color="darkGreen"
          label={orderInfo.devices?.length > 0 ? t("Valider mon panier") : t("Retour au catalogue")}
          fullWidth
          onClick={orderInfo.devices?.length > 0 ? validateBasket : onClose}
        />
      </Layout>
    </Layout>

  ), [t, validateBasket, onClose]);

  return (orderInfo && (
    <Drawer
      open={openModal}
      onClose={onClose}
      title={t("Panier")}
      Actions={DrawerActions}
      anchor="right"
    >
      <Layout
        direction="column"
        spacing={3}
        fullHeight
        justifyContent="space-between"
      >
        {!orderInfo.devices?.length && <ShoppingCartModalEmpty onClose={onClose} />}
        <SecureMdmCartDialog
          setOpen={setSecureMdmCartDialog}
          open={secureMdmCartDialog}
          orderInfo={orderInfo}
        />
        <Layout direction="column" spacing={4} isScrollable divider={<Separator orientation="horizontal" />}>
          {Object.keys(cartItems).map((productId) => Object.keys(cartItems[productId]).map((keyboard) => {
            const mdmKeys = Object.keys(cartItems[productId][keyboard] || {});

            return mdmKeys.map((mdmKey) => (
              <ShoppingCartModalItem
                device={cartItems[productId][keyboard][mdmKey][0]}
                keyboard={keyboard}
                productId={productId}
                deviceNumber={cartItems[productId][keyboard][mdmKey].length}
                orderInfo={orderInfo}
                reloadOrder={reloadOrder}
                reloadOrderInfo={reloadOrderInfo}
                setSecureMdmCartDialog={setSecureMdmCartDialog}
              />
            ));
          }))}

        </Layout>
      </Layout>
    </Drawer>
  ));
};

export default ShoppingCartModal;
