import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import useOrderFilters from "src/hooks/useOrderFilters";
import { useUserContext } from "./UserContext";
import { useToastContext } from "./ToastContext";

const Context = createContext({});

export const useOrderListContext = () => useContext(Context);

export const OrderListProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addToast } = useToastContext();
  const [orders, setOrders] = useState([]);
  const [ordersFiltered, setOrdersFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    filters,
    sortModel,
    onChangeFilters,
    onChangeSortModel,
    applyFilters,
    applySort,
    resetFilters,
    setFilters,
  } = useOrderFilters();

  const fetchOrders = useCallback(async () => {
    try {
      setLoading(true);
      const ordersFetched = await user.api.getVisibleInCockpitOrders();

      setOrders(ordersFetched.data);
      setOrdersFiltered(ordersFetched.data);
      setLoading(false);
    } catch (err) {
      if (err.response.status === 401) {
        console.log("UNAUTHORIZED");
      } else {
        addToast(t("Une erreur est survenue lors du chargement de la liste."));
      }
      setLoading(false);
    }
  }, [user, addToast, t]);

  useEffect(() => {
    if (user && user.isAdmin()) {
      fetchOrders();
    }
  }, [user]);

  useEffect(() => {
    const newFilteredOrders = applyFilters(orders);
    const newFilteredAndSortedOrders = applySort(newFilteredOrders);

    setOrdersFiltered(newFilteredAndSortedOrders);
  }, [filters, sortModel, orders]);

  const resetOrdersFilteredAndFilters = useCallback(() => {
    resetFilters();
    onChangeSortModel("order_number", "desc");
  }, [resetFilters, onChangeSortModel]);

  const values = useMemo(
    () => ({
      filters,
      sortModel,
      onChangeSortModel,
      orders,
      loading,
      ordersFiltered,
      setFilters,
      onChangeFilters,
      resetOrdersFilteredAndFilters,
      reloadOrders: fetchOrders,
    }),
    [filters,
      orders,
      sortModel,
      onChangeSortModel,
      loading,
      ordersFiltered,
      setFilters,
      onChangeFilters,
      resetOrdersFilteredAndFilters,
      fetchOrders,
    ],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
